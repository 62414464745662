<template>
  <BaseListPage locale-section="pages.bracteates" route="bracteate">
    <BracteateTypesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "BracteateTypesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    BracteateTypesList: () => import("./BracteateTypesList")
  }
};
</script>
